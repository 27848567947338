$(document).ready(function () {
  // Marquee animation on hero
  gsap.to('.js-preview-image-animate--first', {
    duration: 15,
    xPercent: '-100', //move each box -100% to left
    repeat: -1,
    ease: 'none',
  });
  gsap.to('.js-preview-image-animate--second', {
    duration: 20,
    xPercent: '100', //move each box 100% to right
    repeat: -1,
    ease: 'none',
  });

  // Marquee Vertical Animation on Page Section
  $('.js-page-card').hover(
    function () {
      $(this).addClass('active');
    },
    function () {
      $(this).removeClass('active');
    }
  );
});
